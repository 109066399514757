/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // hamburger nav enhancements
        var $hamburger = $(".hamburger");
        $hamburger.on("click", function(e) {
          $hamburger.toggleClass("is-active");
          // Do something else, like open/close menu
        });

        $('.header').stickyNavbar({
          activeClass: "active", // Class to be added to highlight nav elements
          sectionSelector: "scrollto", // Class of the section that is interconnected with nav links
          animDuration: 350, // Duration of jQuery animation as well as jQuery scrolling duration
          startAt: 200, // Stick the menu at XXXpx from the top of the this() (nav container)
          easing: "swing", // Easing type if jqueryEffects = true, use jQuery Easing plugin to extend easing types - gsgd.co.uk/sandbox/jquery/easing
          animateCSS: true, // AnimateCSS effect on/off
          animateCSSRepeat: false, // Repeat animation everytime user scrolls
          cssAnimation: "fadeInDown", // AnimateCSS class that will be added to selector
          jqueryEffects: false, // jQuery animation on/off
          // jqueryAnim: "slideDown", // jQuery animation type: fadeIn, show or slideDown
          // selector: "a", // Selector to which activeClass will be added, either "a" or "li"
          mobile: true, // If false, nav will not stick under viewport width of 480px (default) or user defined mobileWidth
          mobileWidth: 480, // The viewport width (without scrollbar) under which stickyNavbar will not be applied (due user usability on mobile)
          zindex: 9999, // The zindex value to apply to the element: default 9999, other option is "auto"
          // stickyModeClass: "sticky", // Class that will be applied to 'this' in sticky mode
          // unstickyModeClass: "unsticky" // Class that will be applied to 'this' in non-sticky mode
        });
        
        $.fn.UItoTop = function(options) {
          var defaults = {
            text: 'To Top',
            min: 200,
            inDelay:300,
            outDelay:300,
            containerID: 'toTop',
            //containerHoverID: 'toTopHover',
            scrollSpeed: 1200,
            easingType: 'linear'
          },
          settings = $.extend(defaults, options),
          containerIDhash = '#' + settings.containerID,
          containerHoverIDHash = '#'+settings.containerHoverID;

          $('body').append('<a href="#" id="'+settings.containerID+'">'+settings.text+'</a>');
          $(containerIDhash).hide().on('click.UItoTop',function(){
            $('html, body').animate({scrollTop:0}, settings.scrollSpeed, settings.easingType);
            //$('#'+settings.containerHoverID, this).stop().animate({'opacity': 0 }, settings.inDelay, settings.easingType);
            return false;
          })
          //.prepend('<span id="'+settings.containerHoverID+'"></span>')
          .hover(function() {
            $(containerHoverIDHash, this).stop().animate({
              'opacity': 1
            }, 600, 'linear');
          }, function() { 
            $(containerHoverIDHash, this).stop().animate({
                'opacity': 0
            }, 700, 'linear');
          });
                    
          $(window).scroll(function() {
            var sd = $(window).scrollTop();
            if(typeof document.body.style.maxHeight === "undefined") {
              $(containerIDhash).css({
                'position': 'absolute',
                'top': sd + $(window).height() - 50
              });
            }
            if ( sd > settings.min ){ 
              $(containerIDhash).fadeIn(settings.inDelay);
            } else {
              $(containerIDhash).fadeOut(settings.Outdelay);
            }
          });
        };

        $().UItoTop({
          min: 480,
          scrollSpeed: 400,
          text: '<span class="webicon webicon-keyboard_arrow_up"></span>'
        });

        // Set options for headhesive
        /*var $headhesive_options = {
            // offset: '#show_here',
            offset: 200,
            offsetSide: 'top',
            classes: {
                clone:   'banner--clone',
                stick:   'banner--stick',
                unstick: 'banner--unstick'
            }
        };*/
          
        // Initialize with options
        // var banner = new Headhesive('.banner', $headhesive_options);
        
        // Headhesive destroy
        // banner.destroy();
        
        var $fancybox_options = {
          // selector : '[data-fancybox="gallery"]',
          infobar : false,
          buttons : [
            'slideShow',
            'fullScreen',
            // 'thumbs',
            // 'share',
            //'download',
            //'zoom',
            'close'
          ],
          idleTime : 99,
          loop: true
        };

        $('[data-fancybox="gallery"]').fancybox($fancybox_options);
        // $('[data-fancybox]').fancybox($fancybox_options);

        // social share
        $('#social-share').find('a.soc').on('click', function(e){

          var 
            width = this.data-width || 800,
            height = this.data-height || 500;

          // popup position
          var
            px = Math.floor(((screen.availWidth || 1024) - width) / 2),
            py = Math.floor(((screen.availHeight || 700) - height) / 2);

          // open popup
          var popup = window.open(this.href, "Social",
            "width="+width+",height="+height+",left="+px+",top="+py+",location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1");

          if (popup) {
            popup.focus();
            if (e.preventDefault){ e.preventDefault(); }
            e.returnValue = false;
          }

          return !!popup;
        }
      );  


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // About us page, note the change from about-us to about_us.
    'post_type_archive_work': {
      init: function() {
        // JavaScript to be fired on the about us page
        
        /*
        var $grid = $('.grid').isotope({
          itemSelector: '.grid-item',
          layoutMode: 'fitRows'
        });
        
        // load grid after images
        $grid.imagesLoaded().done( function() {
          $(".loader").fadeOut(); // disappear the loader
          console.log("loaded"); // let us know
          $grid.isotope('layout'); // layout grid
        }).progress( function() {
          console.log("loading"); // what're you doing?
        });
          
        // filter types, for now
        $('.proj-sort').on( 'click', function() {
          event.preventDefault();
          var filterValue = $( this ).attr('data-filter');
          // use filterFn if matches value
          // filterValue = filterFns[ filterValue ] || filterValue;
          $grid.isotope({ filter: filterValue });
        });

        // change is-checked class on buttons
        $('.tax-list').each( function( i, buttonGroup ) {
          var $buttonGroup = $( buttonGroup );
          $buttonGroup.on( 'click', 'button', function() {
            $buttonGroup.find('.active').removeClass('active');
            $( this ).addClass('active');
          });
        });
        
        // reset button
        $('.button-reset').on('click',function(){
          event.preventDefault();
          console.log("reset grid");
          $grid.isotope({
            filter:'*'
          });
        });

      */
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
